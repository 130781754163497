@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root{
  --primary: '#FACA2B'
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ql-toolbar,
.ql-container {
  background-color: white;
}

.ql-toolbar {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-color: red;
}

.ql-container {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-color: red;
  height: 600px;
}

.ql-editor {
  direction: ltr;
  height: 600px;
  font-size: 16px;
  padding: 20px 40px !important
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.modal-content::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers */
}

.scrollbar-thin::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.scrollbar-track::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollbar-thumb::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.scrollbar-thumb:hover::-webkit-scrollbar-thumb {
  background: #555;
}