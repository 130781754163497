:root {
    --primary-color: #C2CE2E;
    --primary-color-rgb: 194, 206, 46;
}

.rdrDefinedRangesWrapper {
    display: none !important;
}

.rdrDay .rdrDayToday {
    color: var(--primary-color);
}

.rdrDayToday .rdrDayNumber span:after {
    background: none !important;
}

.rdrCalendarWrapper {
    padding: 10px;
    border-radius: 10px;
}

.rdrDateRangePickerWrapper {
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
    background-color: #f5f5f5 !important;
}

.rdrStartEdge,
.rdrEndEdge {
    background-color: var(--primary-color) !important;
}

.rdrInRange~.rdrDayNumber span,
.rdrStartEdge~.rdrDayNumber span,
.rdrEndEdge~.rdrDayNumber span {
    color: black !important;
}

.rdrDateDisplayWrapper {
    background-color: white !important;
}

.rdrDateDisplayItemActive {
    border-color: black !important;
}

.rdrDateDisplayItemActive input {
    color: black !important;
}

.rdrNextPrevButton {
    background-color: white !important;
}

.rdrNextPrevButton:hover {
    background-color: #eee !important;
}

.rdrMonthPicker select {
    background-color: white !important;
}

.rdrYearPicker select {
    background-color: white !important;
}

.rdrInputRanges input {
    background-color: var(--primary-color) !important;
    /* Set the background color for inputs */
    color: #000;
    /* Optional: change the input text color */
}

/* input[type="text"],
input[type="number"],
input[type="date"],
select,
textarea {
    font-weight: 400;
    color: #667085;
} */

.ReactModal__Body--open {
    overflow: hidden;
    width: 100%;
}

.table_row {
    height: 72px;
    cursor: pointer;
}

.table_row:hover {
    background-color: rgba(var(--primary-color-rgb), 0.05);
    /* background-color: rgba(0, 0, 0, 0.05); */
    border-left: 2px solid var(--primary-color);
}

.actions {
    color: white;
}

.actions:hover {
    background-color: rgba(var(--primary-color-rgb), 0.6);
    /* color: red; */
    /* color: var(--primary-color); */
    /* border: 1px solid black; */
}

.MuiMenu-paper {
    transition-duration: 0s !important;
}

select {
    background-color: white;
}